var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.user ? _c('buy-gems-modal') : _vm._e(), _c('footer', {
    staticClass: "col-12 expanded"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-md-5 text-center text-md-left"
  }, [_vm._v(" © " + _vm._s(_vm.currentYear) + " Habitica. All rights reserved. ")])]), _c('div', {
    staticClass: "melior"
  }, [_c('div', {
    staticClass: "logo svg svg-icon color",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.melior)
    }
  })]), _c('div', {
    staticClass: "privacy-terms"
  }, [_c('span', {
    staticClass: "privacy-policy"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "/static/privacy"
    }
  }, [_vm._v(_vm._s(_vm.$t('privacy')))])]), _c('span', {
    staticClass: "terms"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "/static/terms"
    }
  }, [_vm._v(_vm._s(_vm.$t('terms')))])])]), _c('div', {
    staticClass: "privacy-policy mobile desktop"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "/static/privacy"
    }
  }, [_vm._v(_vm._s(_vm.$t('privacy')))])]), _c('div', {
    staticClass: "mobile-terms mobile desktop"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "/static/terms"
    }
  }, [_vm._v(_vm._s(_vm.$t('terms')))])]), _vm.TIME_TRAVEL_ENABLED && _vm.user.permissions && _vm.user.permissions.fullAccess ? _c('div', {
    key: _vm.lastTimeJump,
    staticClass: "time-travel"
  }, [_c('a', {
    staticClass: "btn btn-secondary mr-1",
    on: {
      "click": function ($event) {
        return _vm.jumpTime(-1);
      }
    }
  }, [_vm._v("-1 Day")]), _c('a', {
    staticClass: "btn btn-secondary mr-1",
    on: {
      "click": function ($event) {
        return _vm.jumpTime(-7);
      }
    }
  }, [_vm._v("-7 Days")]), _c('a', {
    staticClass: "btn btn-secondary mr-1",
    on: {
      "click": function ($event) {
        return _vm.jumpTime(-30);
      }
    }
  }, [_vm._v("-30 Days")]), _c('div', {
    staticClass: "my-2"
  }, [_vm._v(" Time Traveling! It is " + _vm._s(new Date().toLocaleDateString()) + " "), _c('a', {
    staticClass: "btn btn-warning btn-small",
    on: {
      "click": function ($event) {
        return _vm.resetTime();
      }
    }
  }, [_vm._v(" Reset ")])]), _c('a', {
    staticClass: "btn btn-secondary mr-1",
    on: {
      "click": function ($event) {
        return _vm.jumpTime(1);
      }
    }
  }, [_vm._v("+1 Day")]), _c('a', {
    staticClass: "btn btn-secondary mr-1",
    on: {
      "click": function ($event) {
        return _vm.jumpTime(7);
      }
    }
  }, [_vm._v("+7 Days")]), _c('a', {
    staticClass: "btn btn-secondary mr-1",
    on: {
      "click": function ($event) {
        return _vm.jumpTime(30);
      }
    }
  }, [_vm._v("+30 Days")])]) : _vm._e(), _vm.DEBUG_ENABLED && _vm.isUserLoaded ? _c('div', {
    staticClass: "debug-toggle"
  }, [_c('button', {
    staticClass: "debug btn-primary",
    on: {
      "click": function ($event) {
        _vm.debugMenuShown = !_vm.debugMenuShown;
      }
    }
  }, [_vm._v(" Toggle Debug Menu ")]), _vm.debugMenuShown ? _c('div', {
    staticClass: "debug-toggle debug-group"
  }, [_c('div', {
    staticClass: "debug-pop"
  }, [_c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.setHealthLow();
      }
    }
  }, [_vm._v("Reduce Health to 1")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addMissedDay(1);
      }
    }
  }, [_vm._v("+1 Missed Day")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addMissedDay(2);
      }
    }
  }, [_vm._v("+2 Missed Days")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addMissedDay(8);
      }
    }
  }, [_vm._v("+8 Missed Days")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addMissedDay(32);
      }
    }
  }, [_vm._v("+32 Missed Days")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addTenGems();
      }
    }
  }, [_vm._v("+10 Gems")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addHourglass();
      }
    }
  }, [_vm._v("+1 Mystic Hourglass")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addGold();
      }
    }
  }, [_vm._v("+500GP")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.plusTenHealth();
      }
    }
  }, [_vm._v("+ 10HP")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addMana();
      }
    }
  }, [_vm._v("+MP")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addLevelsAndGold();
      }
    }
  }, [_vm._v("+Exp +GP +MP")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addExp();
      }
    }
  }, [_vm._v("+Exp")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addOneLevel();
      }
    }
  }, [_vm._v("+1 Level")]), _c('a', {
    staticClass: "btn btn-secondary",
    attrs: {
      "tooltip": "+1000 to boss quests. 300 items to collection quests"
    },
    on: {
      "click": function ($event) {
        return _vm.addQuestProgress();
      }
    }
  }, [_vm._v("Quest Progress Up")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.bossRage();
      }
    }
  }, [_vm._v("+ Boss Rage 😡")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.makeAdmin();
      }
    }
  }, [_vm._v("Make Admin")])])]) : _vm._e()]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }